import { useEffect } from "react";

import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { ReactComponent as Spinner } from "assets/icon/SvgSpinnersBlocksWave.svg";
import { useScoreTest } from "hooks/useScoreTest";

function LinearProgressWithLabel(props = 0) {
  let percentValue = 0;
  if (!isNaN(props.value)) percentValue = props.value;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          sx={{
            "&": {
              background: "#eaeaea",
            },
            "&>.MuiLinearProgress-barColorPrimary": {
              background:
                "linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);",
            },
          }}
          value={percentValue}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          percentValue
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
const DownloadScoreTestModal = ({ id }) => {
  const {
    progressState,
    setScoreTestOn,
    errorMessage,
    scoreTest_isFetching,
    scoreTest_isError,
  } = useScoreTest(id);

  useEffect(() => {
    setScoreTestOn(true);
  }, []);

  return (
    <>
      <DialogTitle>응시 결과처리</DialogTitle>
      <DialogContent>
        <Grid p={2}>
          {scoreTest_isFetching ? (
            <Grid>
              <Grid item container justifyContent={"center"} mb={2}>
                <Spinner />
              </Grid>
              <Typography fontSize="12px" color={"#777"}>
                결과처리 중입니다. 잠시만 기다려 주세요..
              </Typography>
            </Grid>
          ) : scoreTest_isError ? (
            <Typography fontSize="12px" color={"#252525"}>
              ❌ {errorMessage}
            </Typography>
          ) : (
            <Typography fontSize="12px" color={"#252525"}>
              결과처리가 완료 되었습니다. 👏
            </Typography>
          )}
          {scoreTest_isFetching && (
            <LinearProgressWithLabel
              value={Math.round(
                (progressState?.progress * 100) / progressState?.totalProgress
              )}
            />
          )}
        </Grid>
      </DialogContent>
    </>
  );
};

export default DownloadScoreTestModal;
