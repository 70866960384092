export const bizCode = (code) => {
  switch (code) {
    case 1:
    case "1":
      return "M";
    case 2:
    case "2":
      return "E";
    case 3:
    case "3":
      return "R";
    case 4:
    case "4":
      return "S";
    case 0:
    case "0":
      return "비대졸 공통";
    default:
      return "SW";
  }
};
