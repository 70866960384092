import React, { useRef } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Search } from "lucide-react";

export function SearchCompany({ setParamValues }) {
  const searchRef = useRef();
  const submitSearch = () => {
    setParamValues((prev) => ({
      ...prev,
      search: { companyName: searchRef.current.value || "" },
    }));
  };
  return (
    <Grid container columnGap={1}>
      <TextField
        size="small"
        inputRef={searchRef}
        sx={{ width: 300 }}
        placeholder="회사명을 입력해 주세요."
        onKeyDown={(e) => {
          if (e.key === "Enter") submitSearch();
        }}
      />
      <Button
        variant="outlined"
        startIcon={<Search size={15} />}
        onClick={submitSearch}
      >
        검색
      </Button>
    </Grid>
  );
}

export const MemoizedSearchCompany = React.memo(SearchCompany);
