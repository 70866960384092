import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";

import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as AddTester } from "assets/icon/UsergroupAddOutlined.svg";
import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";
import LoadingCircle from "components/UI/organism/LoadingCircle";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import GroupCard from "../groupCards/GroupCard";

import { useNavigate, useParams } from "react-router-dom";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  examMAPatom,
  HRcompanyInfo,
  roomInfoAtom,
  roomsAtom,
} from "recoil/store";

import { useQueryClient } from "@tanstack/react-query";
import useGetFile from "hooks/useGetFile";
import useGetQueryhr from "hooks/useGetQueryhr";
import useMutationHook from "hooks/useMutationHook";

import { GROUP_ROOM_LIST } from "enums/tableColumn";
import { tableHeader, tableWrapper } from "themes/theme";

import _ from "lodash";

import {
  Alert,
  Backdrop,
  Collapse,
  Fade,
  IconButton,
  ListItemIcon,
  Snackbar,
} from "@mui/material";
import { checkIcon, questionIcon, warningIcon } from "assets/icon/Icon_modal";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import TopTitle from "components/templates/topTitle/TopTitle";
import DownloadLoadingBackdrop from "components/UI/DownloadLoadingBackdrop";
import {
  MESSAGE_ASSIGN_ROOM,
  MESSAGE_CREATE_ROOM,
  MESSAGE_DELETE_ROOM,
  MESSAGE_DOWNLOAD_AI_DATA,
  MESSAGE_DOWNLOAD_CUSTOMIZED_REPORT,
  MESSAGE_DOWNLOAD_GROUP_REPORT,
  MESSAGE_DOWNLOAD_INTERVIEWER_REPORT,
  MESSAGE_DOWNLOAD_PERSONAL_REPORT,
  MESSAGE_SCORING,
} from "enums/modalMessage";
import {
  AlignStartVertical,
  BrainCircuit,
  ClipboardList,
  Component,
  Database,
  FileDown,
  FileSpreadsheet,
  FileText,
  FolderPlus,
  FunctionSquare,
  LayoutList,
  Mail,
  User2,
  Users2,
  UserSquare2,
  Video,
} from "lucide-react";
import Swal from "sweetalert2";
import { checkboxSelect } from "utils/checkboxSelect";
import DownloadProgressModalCustomized from "./DownloadProgressModalCustomized";
import DownloadProgressModalInterviewer from "./DownloadProgressModalInterviewer";
import DownloadScoreTestModal from "./DownloadScoreTestModal";
import TesterStatusListDownloadModal from "./TesterStatusListDownloadModal";

const COPY_SUCCESS_MESSAGE = "클립보드에 복사되었습니다.";
const COPY_FAIL_MESSAGE = "클립보드에 복사를 실패하였습니다.";

const RoomListCard = lazy(() => import("./roomList_mobile/RoomListCard"));
const CreateTesterModal = lazy(() => import("./CreateTesterModal"));
const CreateManagerModal = lazy(() => import("./CreateManagerModal"));
const SendMessageModal = lazy(() => import("./SendMessageModal"));
const DownloadTesterListModal = lazy(() => import("./DownloadTesterListModal"));
const DownloadGroupMarkDataModal = lazy(() =>
  import("./DownloadGroupMarkDataModal")
);
const DownloadProgressModal = lazy(() => import("./DownloadProgressModal"));
const TestSetStatus = lazy(() => import("./TestSetStatus"));

export default function RoomList() {
  const navigate = useNavigate();
  const { id, company } = useParams();

  const [selected, setSelected] = useState([]);
  const [downloadTesterListModalOn, setDownloadTesterListModalOn] =
    useState(false);
  const [createTesterModalOn, setCreateTesterModalOn] = useState(false);
  const [createManagerModalOn, setCreateManagerModalOn] = useState(false);
  const [sendMessageModalOn, setSendMessageModalOn] = useState(false);
  const [downloadGroupMarkDataModalOn, setDownloadGroupMarkDataModalOn] =
    useState(false);
  const [reportDownloadToggle, setReportDownloadToggle] = useState(false);
  const [reportDownloadPersonalToggle, setReportDownloadPersonalToggle] =
    useState(false);
  const [reportDownloadInterviewerToggle, setReportDownloadInterviewerToggle] =
    useState(false);
  const [reportDownloadCustomizedToggle, setReportDownloadCustomizedToggle] =
    useState(false);
  const [testerStatusListModalOn, setTesterStatusListModalOn] = useState(false);
  const [targetProgress, setTargetProgress] = useState(0);
  const [targetProgressInterviewer, setTargetProgressInterviewer] = useState(0);
  const [targetProgressCustomized, setTargetProgressCustomized] = useState(0);
  const queryClient = useQueryClient();
  const [progressModalOn, setProgressModalOn] = useState(false);
  const [progressModalOnInterviewer, setProgressModalOnInterviewer] =
    useState(false);
  const [progressModalOnCustomized, setProgressModalOnCustomized] =
    useState(false);
  const [progressModalOnScoreTest, setProgressModalOnScoreTest] =
    useState(false);
  const [paramValues, setParamValues] = useState({
    perPage: 1200,
  });

  const [copyResult, setCopyResult] = useState(false);
  const [isCopyError, setIsCopyError] = useState(false);
  const [copyResultMessage, setCopyResultMessage] = useState("");
  const handleCloseAlert = () => setCopyResult(!copyResult);

  const companyInfo = useRecoilValue(HRcompanyInfo);
  const setRoomInfo = useSetRecoilState(roomInfoAtom);
  const roomIdxs = useSetRecoilState(roomsAtom);
  const examMAP = useSetRecoilState(examMAPatom);

  const isMobile = useMediaQuery("(max-width:500px)");

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  //* 해당 그룹 화상룸 상세정보 조회
  const {
    data: groupInfo,
    isLoading: groupInfoDataLoading,
    isError: groupInfoDataError,
  } = useGetQueryhr("getGroupDetail", { groupIdx: id });

  //* 화상룸목록
  const { data, pageInfo, isFetching, isLoading, isError, refetch } =
    useGetQueryhr("roomList", { ...paramValues, ...{ index: id } });

  //* 그룹 레포트 다운로드
  const {} = useGetFile(
    "HR",
    "reportDownloadGroup",
    { groupIdx: Number(id) },
    reportDownloadToggle,
    setReportDownloadToggle
  );

  useEffect(() => {
    data && setRoomInfo(data);
    data && roomIdxs(data.data);
  }, [data, isFetching, isLoading]);

  useEffect(() => {
    groupInfo && examMAP(groupInfo.examMAP);
  }, [groupInfo]);

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    setSelected([]);
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  //* 룸 일괄배정
  const { mutate: assignRoom } = useMutationHook("assignRoom", "HR");

  //* 개별 화상룸 추가
  const { mutate: addOneRoom } = useMutationHook("addOneRoom", "HR");

  //* 개별 화상룸 삭제
  const { mutate: deleteSelectRoom } = useMutationHook(
    "deleteSelectRoom",
    "HR"
  );

  //* 전체 HR 레포트 탬플릿 생성
  const { mutateAsync } = useMutationHook("groupHRreport", "HR");

  //*   모든 개인 레포트 탬플릿 생성
  const {
    isLoading: createPersonalReport_isLoading,
    mutate: createPersonalHRreport,
  } = useMutationHook("personalHRreport", "HR");
  //*   모든 면접 질문용 레포트 탬플릿 생성
  const {
    isLoading: createInterviewerReport_isLoading,
    mutate: createInterviewerReport,
  } = useMutationHook("interviewerReport", "HR");

  //*   모든 특화요인 탬플릿 생성
  const {
    isLoading: createCustomizedReport_isLoading,
    mutate: createCustomizedReport,
  } = useMutationHook("customizedReport", "HR");

  useEffect(() => {
    if (
      createInterviewerReport_isLoading ||
      createCustomizedReport_isLoading ||
      createPersonalReport_isLoading
    ) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [
    createCustomizedReport_isLoading,
    createInterviewerReport_isLoading,
    createPersonalReport_isLoading,
  ]);

  //* CheckBox
  const handleCheckClick = (_, name) => {
    const { roomIdx } = name;
    const selectedIndex = selected.indexOf(roomIdx);
    setSelected(checkboxSelect(selected, selectedIndex, roomIdx));
  };

  //* 전체선택 체크박스
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.data.map((n) => n.roomIdx);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleDeleteSelectRoom = () =>
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DELETE_ROOM,
        title: "화상룸 삭제",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        deleteSelectRoom(
          { groupIdx: Number(id), roomIdxList: selected },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "화상룸 삭제가 완료되었습니다.",
                  title: "화상룸 삭제",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("roomList");
              });
            },
          }
        );
      }
    });

  const handleAssignRoom = () => {
    Swal.fire(
      confirmModalConfig({
        html: MESSAGE_ASSIGN_ROOM,
        title: "화상룸 배정",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        assignRoom(
          { groupIdx: Number(id) },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "화상룸 배정이 완료되었습니다.",
                  title: "화상룸 배정",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("roomList");
              });
            },
          }
        );
      }
    });
  };
  const handleScoring = () => {
    Swal.fire(
      confirmModalConfig({
        html: MESSAGE_SCORING,
        title: "응시 결과처리",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        handleProgressModalScoreTest();
      }
    });
  };

  const handleAddOneRoom = () => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_CREATE_ROOM,
        title: "화상룸 추가",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        addOneRoom(
          { groupIdx: Number(id) },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "화상룸 추가가 완료되었습니다.",
                  title: "화상룸 추가",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("roomList");
              });
            },
          }
        );
      }
    });
  };

  const [markType, setMarkType] = useState("");

  //* MODAL

  const handleCreateTesterModal = () =>
    setCreateTesterModalOn(!createTesterModalOn);
  const handleCreateManagerModal = () =>
    setCreateManagerModalOn(!createManagerModalOn);
  const handleSendMessageModal = () =>
    setSendMessageModalOn(!sendMessageModalOn);
  const handleProgressModalScoreTest = () =>
    setProgressModalOnScoreTest(!progressModalOnScoreTest);
  const handleDownloadGroupMarkData = (_, type) => {
    handleCloseDownloadListMenu();
    handleCloseDownloadMarkData();

    if (companyInfo.companyCode !== "ACG") {
      return Swal.fire(
        alertModalConfig({
          text: "준비중입니다.",
          title: "응답값 다운로드",
          iconHtml: warningIcon,
          confirmButtonText: "확인",
        })
      );
    } else {
      setMarkType(type);
      setDownloadGroupMarkDataModalOn(!downloadGroupMarkDataModalOn);
    }
  };

  const handleDownloadTesterListModal = () => {
    handleCloseDownloadListMenu();
    setDownloadTesterListModalOn(!downloadTesterListModalOn);
  };

  const handleDownloadTesterStatusList = () => {
    handleCloseDownloadListMenu();
    setTesterStatusListModalOn(!testerStatusListModalOn);
  };
  const handleProgressModal = () => {
    setReportDownloadPersonalToggle(false);
    setProgressModalOn(!progressModalOn);
  };
  const handleProgressModalInterviewer = () =>
    setProgressModalOnInterviewer(!progressModalOnInterviewer);
  const handleProgressModalCustomized = () =>
    setProgressModalOnCustomized(!progressModalOnCustomized);

  const handleClickRow = (e, row) => {
    if (e.target.cellIndex >= 1) {
      navigate(`/acghr_hr/${company}/roomList/testerList${row.roomIdx}`, {
        state: { groupIdx: id, roomNo: row.roomNo, total: 1200 },
      });
    }
  };

  const [downloadAIdata, setDownloadAIdata] = useState(false);

  //* 감독관 템플릿 다운로드
  const { data: downloadAiData } = useGetFile(
    "HR",
    "downloadAiData",
    { groupIdx: Number(id) },
    downloadAIdata,
    setDownloadAIdata
  );

  const handleDownloadAIdata = () => {
    handleCloseDownloadListMenu(); //* CLOSE DROPDOWN MENU
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DOWNLOAD_AI_DATA,
        title: "AI 데이터 다운로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        focusConfirm: true,
        showLoaderOnConfirm: true,
      })
    ).then((result) => {
      if (result.isConfirmed) {
        setDownloadAIdata(!downloadAIdata);
      }
    });
  };

  const handleCreateGroupHRreport = () => {
    handleCloseDownloadListMenu(); //* CLOSE DROPDOWN MENU
    handleCloseReportDownloadListMenu();

    if (companyInfo.companyCode === "ACG") {
      Swal.fire(
        confirmModalConfig({
          text: MESSAGE_DOWNLOAD_GROUP_REPORT,
          title: "레포트 다운로드",
          iconHtml: questionIcon,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
          focusConfirm: true,
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          preConfirm: async (res) => {
            return mutateAsync({
              groupIdx: Number(id),
            });
          },
        })
      ).then((result) => {
        if (result.isConfirmed) {
          setReportDownloadToggle(!reportDownloadToggle);
        }
      });
    } else {
      return Swal.fire(
        alertModalConfig({
          text: "준비중입니다.",
          title: "레포트 다운로드",
          iconHtml: warningIcon,
          confirmButtonText: "확인",
        })
      );
    }
  };

  // HR담당자용
  const isSuccess = (res) => {
    const { progressNo, totalProgress } = res.data.data;
    setTargetProgress(Math.round((progressNo / totalProgress) * 100));

    if (progressNo >= totalProgress) {
      return;
    }

    createPersonalHRreport(
      {
        groupIdx: Number(id),
        progressNo: progressNo + 1,
      },
      {
        onSuccess: (res) => {
          isSuccess(res);
        },
      }
    );
  };

  // 면접관용
  const isSuccessInterviewer = (res) => {
    const { progressNo, totalProgress } = res.data.data;
    setTargetProgressInterviewer(
      Math.round((progressNo / totalProgress) * 100)
    );

    if (progressNo >= totalProgress) {
      return;
    }

    createInterviewerReport(
      {
        groupIdx: Number(id),
        progressNo: progressNo + 1,
      },
      {
        onSuccess: (res) => {
          isSuccessInterviewer(res);
        },
      }
    );
  };

  //  특화요인 레포트
  const isSuccessCustomized = (res) => {
    const { progressNo, totalProgress } = res.data.data;
    setTargetProgressCustomized(Math.round((progressNo / totalProgress) * 100));

    if (progressNo >= totalProgress) {
      return;
    }

    createCustomizedReport(
      {
        groupIdx: Number(id),
        progressNo: progressNo + 1,
      },
      {
        onSuccess: (res) => {
          isSuccessCustomized(res);
        },
      }
    );
  };

  // HR담당자용
  const handleCreatePersonalReport = () => {
    handleCloseDownloadListMenu();
    handleCloseReportDownloadListMenu();

    if (companyInfo.companyCode === "ACG") {
      Swal.fire(
        confirmModalConfig({
          text: MESSAGE_DOWNLOAD_PERSONAL_REPORT,
          title: "레포트 다운로드",
          iconHtml: questionIcon,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
        })
      ).then((result) => {
        if (result.isConfirmed) {
          createPersonalHRreport(
            {
              groupIdx: Number(id),
              progressNo: 1,
            },
            {
              onSuccess: (res) => {
                isSuccess(res);
                handleProgressModal();
              },
            }
          );
        }
      });
    } else {
      return Swal.fire(
        alertModalConfig({
          text: "준비중입니다.",
          title: "레포트 다운로드",
          iconHtml: warningIcon,
          confirmButtonText: "확인",
        })
      );
    }
  };

  const handleCreateInterviewerReport = () => {
    handleCloseDownloadListMenu(); //* CLOSE DROPDOWN MENU
    handleCloseReportDownloadListMenu();
    if (companyInfo.companyCode === "ACG") {
      Swal.fire(
        confirmModalConfig({
          text: MESSAGE_DOWNLOAD_INTERVIEWER_REPORT,
          title: "면접관용 레포트 다운로드",
          iconHtml: questionIcon,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
        })
      ).then((result) => {
        if (result.isConfirmed) {
          createInterviewerReport(
            {
              groupIdx: Number(id),
              progressNo: 1,
            },
            {
              onSuccess: (res) => {
                isSuccessInterviewer(res);
                handleProgressModalInterviewer();
              },
            }
          );
        }
      });
    } else {
      return Swal.fire(
        alertModalConfig({
          text: "준비중입니다.",
          title: "레포트 다운로드",
          iconHtml: warningIcon,
          confirmButtonText: "확인",
        })
      );
    }
  };
  const handleCreateCustomizedReport = () => {
    handleCloseDownloadListMenu(); //* CLOSE DROPDOWN MENU
    handleCloseReportDownloadListMenu();

    if (companyInfo.companyCode === "ACG") {
      Swal.fire(
        confirmModalConfig({
          text: MESSAGE_DOWNLOAD_CUSTOMIZED_REPORT,
          title: "특화요인 레포트 다운로드",
          iconHtml: questionIcon,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
        })
      ).then((result) => {
        if (result.isConfirmed) {
          createCustomizedReport(
            {
              groupIdx: Number(id),
              progressNo: 1,
            },
            {
              onSuccess: (res) => {
                isSuccessCustomized(res);
                handleProgressModalCustomized();
              },
            }
          );
        }
      });
    } else {
      return Swal.fire(
        alertModalConfig({
          text: "준비중입니다.",
          title: "레포트 다운로드",
          iconHtml: warningIcon,
          confirmButtonText: "확인",
        })
      );
    }
  };

  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [downloadMarkDataAnchorEl, setDownloadMarkDataAnchorEl] =
    useState(null);
  const [reportMenuEl, setReportMenuEl] = useState(null);

  const openDownloadMenu = Boolean(downloadAnchorEl);
  const openDownloadReportMenu = Boolean(reportMenuEl);
  const openDownloadMarkDataMenu = Boolean(downloadMarkDataAnchorEl);

  const handleOpenDownloadListMenu = (e) =>
    setDownloadAnchorEl(e.currentTarget);
  const handleOpenDownloadReportListMenu = (e) =>
    setReportMenuEl(e.currentTarget);
  const handleOpenDownloadMarkData = (e) =>
    setDownloadMarkDataAnchorEl(e.currentTarget);

  const handleCloseDownloadListMenu = () => setDownloadAnchorEl(null);
  const handleCloseReportDownloadListMenu = () => setReportMenuEl(null);
  const handleCloseDownloadMarkData = () => setDownloadMarkDataAnchorEl(null);

  const handleCopyClipBoard = async (text) => {
    try {
      setCopyResult(true);
      await navigator.clipboard.writeText(text);
      setCopyResultMessage(COPY_SUCCESS_MESSAGE);
    } catch (e) {
      setIsCopyError(true);
      setCopyResultMessage(COPY_FAIL_MESSAGE);
    }
  };

  const [testSetStatus, setTestSetStatus] = useState(false);
  const handleTestSetStatusModal = () => {
    setTestSetStatus(!testSetStatus);
  };

  return (
    <>
      <Box
        width="100%"
        height="100%"
        borderRadius={2}
        display="flex"
        flexDirection="column"
        rowGap={3}
      >
        <Grid container justifyContent={"space-between"}>
          <TopTitle
            list={[
              {
                title: "채용그룹 관리",
                url: `/acghr_hr/${company}/group`,
                current: false,
              },
              {
                title: "화상룸 목록",
                url: `/acghr_hr/${company}/roomList${id}`,
                current: true,
              },
            ]}
          />

          <Box>
            <Stack direction={"row"} gap={isMobile ? 0.3 : 1}>
              {companyInfo.companyIdx === 1 ? (
                <>
                  <Button
                    variant="outlined"
                    size={isMobile ? "small" : "medium"}
                    onClick={handleTestSetStatusModal}
                    startIcon={<FileSpreadsheet strokeWidth={1.2} size={18} />}
                  >
                    검사 SET 현황
                  </Button>
                  <Button
                    variant="outlined"
                    size={isMobile ? "small" : "medium"}
                    onClick={handleAssignRoom}
                    startIcon={<Component size={17} strokeWidth={1.3} />}
                  >
                    룸 배정
                  </Button>
                  <Button
                    variant="outlined"
                    size={"small"}
                    onClick={handleAddOneRoom}
                    disabled={
                      data &&
                      _.find(data?.data, { roomNo: 0 })?.preDone < 1 &&
                      data.data?.length < 2
                        ? true
                        : false
                    }
                    startIcon={<FolderPlus size={17} strokeWidth={1.3} />}
                  >
                    룸 추가
                  </Button>
                  <Button
                    startIcon={<FunctionSquare size={17} strokeWidth={1.3} />}
                    onClick={handleScoring}
                    variant="outlined"
                  >
                    결과 처리
                  </Button>
                </>
              ) : null}
              <Button
                startIcon={<FileDown size={17} strokeWidth={1.3} />}
                onClick={handleOpenDownloadListMenu}
                variant="outlined"
              >
                다운로드
              </Button>
              <Menu
                anchorEl={downloadAnchorEl}
                open={openDownloadMenu}
                onClose={handleCloseDownloadListMenu}
              >
                {companyInfo.companyIdx === 1 ? (
                  <MenuItem
                    onClick={handleDownloadTesterListModal}
                    sx={{
                      "&>.MuiListItemIcon-root": {
                        minWidth: 25,
                      },
                    }}
                  >
                    <ListItemIcon>
                      <ClipboardList size={17} />
                    </ListItemIcon>
                    출석부
                  </MenuItem>
                ) : null}
                {companyInfo.companyIdx === 1 ? (
                  <MenuItem
                    sx={{
                      "&>.MuiListItemIcon-root": {
                        minWidth: 25,
                      },
                    }}
                    onClick={handleOpenDownloadMarkData}
                  >
                    <ListItemIcon>
                      <Database size={17} />
                    </ListItemIcon>
                    응답값
                  </MenuItem>
                ) : null}
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={handleDownloadTesterStatusList}
                >
                  <ListItemIcon>
                    <LayoutList size={17} />
                  </ListItemIcon>
                  응시자 현황
                </MenuItem>
                {companyInfo.companyIdx === 1 ? (
                  <MenuItem
                    sx={{
                      "&>.MuiListItemIcon-root": {
                        minWidth: 25,
                      },
                    }}
                    onClick={handleDownloadAIdata}
                  >
                    <ListItemIcon>
                      <BrainCircuit size={17} />
                    </ListItemIcon>
                    AI 감지 Data
                  </MenuItem>
                ) : null}
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={handleOpenDownloadReportListMenu}
                >
                  <ListItemIcon>
                    <FileText size={17} />
                  </ListItemIcon>
                  레포트
                </MenuItem>
              </Menu>
              {/* 레포트 */}
              <Menu
                anchorEl={reportMenuEl}
                open={openDownloadReportMenu}
                onClose={handleCloseReportDownloadListMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={handleCreateGroupHRreport}
                >
                  <ListItemIcon>
                    <Users2 size={17} />
                  </ListItemIcon>
                  그룹
                </MenuItem>
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={handleCreatePersonalReport}
                >
                  <ListItemIcon>
                    <User2 size={17} />
                  </ListItemIcon>
                  개인
                </MenuItem>
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={handleCreateInterviewerReport}
                >
                  <ListItemIcon>
                    <UserSquare2 size={17} />
                  </ListItemIcon>
                  면접관
                </MenuItem>
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={handleCreateCustomizedReport}
                >
                  <ListItemIcon>
                    <AlignStartVertical size={17} />
                  </ListItemIcon>
                  특화요인
                </MenuItem>
              </Menu>
              {/* 응답값 */}
              <Menu
                anchorEl={downloadMarkDataAnchorEl}
                open={openDownloadMarkDataMenu}
                onClose={handleCloseDownloadMarkData}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {companyInfo.companyIdx === 1 ? (
                  <MenuItem
                    sx={{
                      "&>.MuiListItemIcon-root": {
                        minWidth: 25,
                      },
                    }}
                    onClick={(e) => handleDownloadGroupMarkData(_, "MARK")}
                  >
                    <ListItemIcon>
                      <Users2 size={17} />
                    </ListItemIcon>
                    Raw Data
                  </MenuItem>
                ) : null}
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={(e) => handleDownloadGroupMarkData(_, "INTERVAL")}
                >
                  <ListItemIcon>
                    <User2 size={17} />
                  </ListItemIcon>
                  풀이시간
                </MenuItem>
              </Menu>
            </Stack>
          </Box>
        </Grid>
        <Grid>
          {groupInfo && (
            <Card
              variant="outlined"
              sx={{
                background: "transparent",
                border: "none",
                "& .MuiCardContent-root:last-child": { padding: 0 },
              }}
            >
              <CardContent>
                <GroupCard
                  selectedItem={groupInfo}
                  status={isLoading || isFetching}
                />
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid
          container
          alignItems={isMobile ? "flex-start" : "center"}
          justifyContent="space-between"
          flexDirection={isMobile && "column-reverse"}
        >
          <Box>
            <Grid container alignItems="center">
              <Typography
                fontSize={"13px"}
                fontWeight={700}
              >{`룸 갯수 : 총 ${pageInfo.total.toLocaleString()} 개`}</Typography>
              <Collapse
                unmountOnExit
                orientation="horizontal"
                in={
                  selected.length === 0 || selected.length >= 2 ? false : true
                }
                sx={{
                  "&": {
                    display: "inline-block",
                    ml: 1,
                  },
                  "& .MuiCollapse-hidden": {
                    height: 0,
                  },
                }}
              >
                <Tooltip
                  TransitionComponent={Fade}
                  title="한번에 1개씩만 삭제 가능합니다."
                  placement="top-start"
                  arrow
                >
                  <Box component="span">
                    <IconButton
                      disabled={
                        selected.length === 0 || selected.length >= 2
                          ? true
                          : false
                      }
                      variant="outlined"
                      size="small"
                      sx={{
                        background: "#ffe8e8",
                        borderRadius: "50%",
                      }}
                      onClick={handleDeleteSelectRoom}
                    >
                      <RemoveRoundedIcon color="warning" />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Collapse>
            </Grid>
          </Box>
          <Box>
            <Grid item>
              <Grid container columnGap={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleCreateTesterModal}
                    sx={{
                      "&>span>svg": { width: 20, height: 20 },
                    }}
                    disableElevation
                    startIcon={<AddTester />}
                  >
                    응시자 등록/추가
                  </Button>
                </Grid>
                {companyInfo.companyIdx === 1 ? (
                  <>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={handleCreateManagerModal}
                        sx={{ "&>span>svg": { width: 20, height: 20 } }}
                        startIcon={<Video size={17} strokeWidth={1.3} />}
                      >
                        감독관
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={handleSendMessageModal}
                        startIcon={<Mail size={17} strokeWidth={1.3} />}
                      >
                        메세지
                      </Button>
                    </Grid>
                  </>
                ) : null}
                {/* <Grid item>
                <Button
                  variant="outlined"
                  onClick={handleDeleteSelectRoom}
                  disabled={selected.length <= 0 ? true : false}
                >
                  화상룸 삭제
                </Button>
              </Grid> */}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {isFetching || isLoading ? (
          <LoadingSkeleton />
        ) : isMobile ? (
          <Grid container rowGap={1} flexDirection="column">
            {data &&
              data.data?.map((room) => (
                <RoomListCard
                  props={{ ...room, handleCheckClick, isSelected }}
                />
              ))}
          </Grid>
        ) : (
          <>
            <Paper
              sx={{
                ...tableWrapper,
                overflowY: "hidden",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              variant="outlined"
            >
              {data && data.data?.length < 1 ? (
                <Box
                  height={"100%"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent="center"
                  py={10}
                >
                  <EmptyPlaceholder />
                </Box>
              ) : (
                <>
                  <TableContainer sx={{ height: "calc(93% - 20px)" }}>
                    <Table stickyHeader sx={tableHeader} size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox" align="center">
                            <Checkbox
                              size="small"
                              color="primary"
                              onChange={handleSelectAllClick}
                            />
                          </TableCell>
                          {GROUP_ROOM_LIST.map((item) => (
                            <TableCell
                              key={item.label}
                              sx={{ width: item.size, fontWeight: "bold" }}
                              align="center"
                            >
                              {item.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data &&
                          data.data?.map((row) => (
                            <TableRow
                              hover
                              key={row.roomIdx}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                cursor: "pointer",
                              }}
                              tabIndex={-1}
                            >
                              <TableCell
                                padding="none"
                                align="center"
                                onClick={(e) => handleClickRow(e, row)}
                              >
                                <Checkbox
                                  size="small"
                                  color="primary"
                                  onClick={(event) =>
                                    handleCheckClick(event, row)
                                  }
                                  checked={isSelected(row.roomIdx)}
                                />
                              </TableCell>
                              <TableCell
                                padding="none"
                                align="center"
                                scope="row"
                                onClick={(e) => handleClickRow(e, row)}
                              >
                                {row.roomNo === 0 ? "대기" : row.roomNo}
                              </TableCell>
                              <TableCell
                                padding="none"
                                align="center"
                                onClick={(e) => handleClickRow(e, row)}
                              >
                                {row.managerName}
                              </TableCell>
                              <TableCell
                                padding="none"
                                align="center"
                                onClick={(e) => handleClickRow(e, row)}
                              >
                                {row.managerCell}
                              </TableCell>
                              <TableCell
                                padding="none"
                                align="center"
                                onClick={(e) =>
                                  row.authKey === null
                                    ? handleClickRow(e, row)
                                    : handleCopyClipBoard(row.authKey)
                                }
                              >
                                <Grid
                                  container
                                  alignItems={"center"}
                                  justifyContent="center"
                                  sx={{
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                >
                                  <Typography>{row.authKey}</Typography>
                                  {row.roomNo !== 0 && (
                                    <ContentCopyRoundedIcon
                                      fontSize="small"
                                      sx={{ color: "#777", ml: 1 }}
                                    />
                                  )}
                                </Grid>
                              </TableCell>
                              <TableCell
                                padding="none"
                                align="center"
                                onClick={(e) => handleClickRow(e, row)}
                              >
                                {row.total}
                              </TableCell>
                              <TableCell
                                padding="none"
                                align="center"
                                onClick={(e) => handleClickRow(e, row)}
                              >
                                {row.preDone}
                              </TableCell>
                              <TableCell
                                padding="none"
                                align="center"
                                onClick={(e) => handleClickRow(e, row)}
                              >
                                {row.done}
                              </TableCell>
                              <TableCell
                                padding="none"
                                align="center"
                                onClick={(e) => handleClickRow(e, row)}
                              >
                                {row.inProgress}
                              </TableCell>
                              <TableCell
                                padding="none"
                                align="center"
                                onClick={(e) => handleClickRow(e, row)}
                              >
                                {row.notDone}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent="center"
                    my={1}
                  >
                    <Pagination
                      size="small"
                      count={pageInfo.totalPage}
                      page={pageInfo.pageNo}
                      onChange={(_, page) => {
                        setParamValues((prev) => ({ ...prev, pageNo: page }));
                      }}
                    />
                  </Grid>
                </>
              )}
            </Paper>
          </>
        )}
      </Box>
      <Dialog
        open={downloadTesterListModalOn}
        onClose={handleDownloadTesterListModal}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingCircle />}>
          <DownloadTesterListModal
            groupIdx={id}
            roomIdx={selected}
            onClose={handleDownloadTesterListModal}
          />
        </Suspense>
      </Dialog>

      {/* 응시자 등록 모달 */}
      <Dialog
        open={createTesterModalOn}
        onClose={handleCreateTesterModal}
        fullWidth
        maxWidth="sm"
      >
        <Suspense fallback={<LoadingCircle />}>
          <CreateTesterModal
            groupIdx={id}
            roomIdx={selected}
            onClose={handleCreateTesterModal}
          />
        </Suspense>
      </Dialog>
      {/* 감독관 등록 모달 */}
      <Dialog
        open={createManagerModalOn}
        onClose={handleCreateManagerModal}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingCircle />}>
          <CreateManagerModal
            groupIdx={id}
            roomIdx={selected}
            setSelected={setSelected}
            onClose={handleCreateManagerModal}
          />
        </Suspense>
      </Dialog>
      {/* 메세지 전송 모달 */}
      <Dialog
        open={sendMessageModalOn}
        onClose={handleSendMessageModal}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingCircle />}>
          <SendMessageModal
            rooms={data}
            groupIdx={id}
            roomIdx={selected}
            onClose={handleSendMessageModal}
          />
        </Suspense>
      </Dialog>
      {/* 그룹 마킹값 다운로드 */}
      <Dialog
        open={downloadGroupMarkDataModalOn}
        onClose={handleDownloadGroupMarkData}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingCircle />}>
          <DownloadGroupMarkDataModal
            groupIdx={id}
            onClose={handleDownloadGroupMarkData}
            markType={markType}
          />
        </Suspense>
      </Dialog>
      {/* 응시자 현황 다운로드 */}
      <Dialog
        open={testerStatusListModalOn}
        onClose={handleDownloadTesterStatusList}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingCircle />}>
          <TesterStatusListDownloadModal
            groupIdx={id}
            onClose={handleDownloadTesterStatusList}
          />
        </Suspense>
      </Dialog>
      {/* Progress Modal */}
      <Dialog
        open={progressModalOn}
        onClose={handleProgressModal}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingCircle />}>
          <DownloadProgressModal
            onClose={handleProgressModal}
            value={targetProgress}
            id={Number(id)}
            reportDownloadPersonalToggle={reportDownloadPersonalToggle}
            setReportDownloadPersonalToggle={setReportDownloadPersonalToggle}
          />
        </Suspense>
      </Dialog>
      {/* Progress Modal */}
      <Dialog
        open={progressModalOnInterviewer}
        onClose={handleProgressModalInterviewer}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingCircle />}>
          <DownloadProgressModalInterviewer
            onClose={handleProgressModalInterviewer}
            value={targetProgressInterviewer}
            id={Number(id)}
            reportDownloadInterviewerToggle={reportDownloadInterviewerToggle}
            setReportDownloadInterviewerToggle={
              setReportDownloadInterviewerToggle
            }
          />
        </Suspense>
      </Dialog>

      <Dialog
        open={progressModalOnScoreTest}
        onClose={handleProgressModalScoreTest}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingCircle />}>
          <DownloadScoreTestModal
            id={Number(id)}
            onClose={handleProgressModalScoreTest}
          />
        </Suspense>
      </Dialog>
      {/* Progress Modal */}
      <Dialog
        open={progressModalOnCustomized}
        onClose={handleProgressModalCustomized}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingCircle />}>
          <DownloadProgressModalCustomized
            onClose={handleProgressModalCustomized}
            value={targetProgressCustomized}
            id={Number(id)}
            reportDownloadCustomizedToggle={reportDownloadCustomizedToggle}
            setReportDownloadCustomizedToggle={
              setReportDownloadCustomizedToggle
            }
          />
        </Suspense>
      </Dialog>

      {/* 검사세트 현황 */}
      <Dialog
        open={testSetStatus}
        onClose={handleTestSetStatusModal}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingCircle />}>
          <TestSetStatus onClose={handleTestSetStatusModal} />
        </Suspense>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={copyResult}
        autoHideDuration={1000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={isCopyError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          <Typography fontWeight={700}>{copyResultMessage}</Typography>
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <DownloadLoadingBackdrop text="레포트 정보를 생성중 입니다. . ." />
      </Backdrop>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openScoreTest}
      >
        <DownloadLoadingBackdrop text="응시자 결과처리 진행중 입니다. . ." />
      </Backdrop> */}
    </>
  );
}
