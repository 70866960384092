import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { ReactComponent as Spinner } from "assets/icon/SvgSpinnersBlocksWave.svg";
import { ReactComponent as ConfirmCircle } from "assets/icon/confirmCircle.svg";
import useGetFile from "hooks/useGetFile";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          sx={{
            "&": {
              background: "#eaeaea",
            },
            "&>.MuiLinearProgress-barColorPrimary": {
              background:
                "linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);",
            },
          }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
const DownloadProgressModal = ({
  onClose,
  value,
  id,
  reportDownloadPersonalToggle,
  setReportDownloadPersonalToggle,
  pilotFlag = false,
}) => {
  const { data } = useGetFile(
    "HR",
    "reportDownloadPersonal",
    { groupIdx: id },
    reportDownloadPersonalToggle,
    setReportDownloadPersonalToggle,
    pilotFlag
  );
  const handleTriggerDownload = () => {
    setReportDownloadPersonalToggle(true);
  };

  useEffect(() => {
    setReportDownloadPersonalToggle(false);
  }, []);

  useEffect(() => {
    data && data.status === 200 && onClose();
  }, [data]);

  return (
    <>
      <DialogTitle>템플릿 다운로드</DialogTitle>
      <DialogContent>
        <Grid pt={2}>
          {value < 100 ? (
            <Grid>
              <Grid item container justifyContent={"center"} mb={2}>
                <Spinner />
              </Grid>
              <Typography fontSize="12px" color={"#777"}>
                응시자 정보를 취합중입니다. 잠시만 기다려 주세요..
              </Typography>
            </Grid>
          ) : (
            <Grid>
              <Grid item container justifyContent={"center"} mb={2}>
                <ConfirmCircle />
              </Grid>
              <Typography
                color={"#181f62"}
                fontSize="12px"
                whiteSpace="pre-wrap"
              >
                {`아래 '확인'버튼을 누르면 다운로드됩니다.`}
              </Typography>
            </Grid>
          )}
          <LinearProgressWithLabel value={value} />
          <Grid container justifyContent={"flex-end"} mt={3}>
            <Button variant="contained" onClick={handleTriggerDownload}>
              확인
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default DownloadProgressModal;
