import React, { useState, useEffect, useCallback } from "react";

import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";

import useGetQueryhr from "hooks/useGetQueryhr";
import { focusManager, useQueryClient } from "@tanstack/react-query";
import useMutationHook from "hooks/useMutationHook";

import { MESSAGE_UPDATE_GROUP } from "enums/modalMessage";
import { jwtDecoder } from "utils/jwtDecoder";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { Box, FormHelperText, Step, StepButton, Stepper } from "@mui/material";
import Swal from "sweetalert2";
import { checkIcon, questionIcon, warningIcon } from "assets/icon/Icon_modal";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import { ConfirmSwitch } from "components/UI/ConfirmSwitch";
const steps = ["기본정보 입력", "검사 유형", "검사 옵션"];
const defaultValues = {
  groupName: "",
  roomUseYN: "",
  roomMaxUserCnt: 0,
  screenShareUseYN: "Y",
  mobileShareUseYN: "",
  preExamSAt: "",
  preExamEAt: "",
  examSAt: "",
  examEAt: "",
  normIdx: 0,
  testGroupYN: "",
  examMAP: "",
  // partIdx: "",
};

const Title = (props) => (
  <Typography lineHeight={2} fontWeight={700} mb={0.5}>
    {props.children}
  </Typography>
);

export default function UpdateGroup({
  onClose,
  selectedRow,
  setSelected,
  pilotFlag = false,
}) {
  const { company } = useParams();
  const companyInfo = jwtDecoder(
    sessionStorage.getItem(company) || localStorage.getItem(company)
  );
  const [expandedRoomUseYN, setExpandedRoomUseYN] = useState(false);
  const [submitValues, setSubmitValues] = useState(defaultValues);
  const [isACG, setIsACG] = useState(false);
  const [queryToggleCompanyList, setQueryToggleCompanyList] = useState(false);
  const queryClient = useQueryClient();
  const [preExamValue, setPreExamValue] = useState([
    selectedRow.preExamSAt === null
      ? new Date()
      : new Date(selectedRow.preExamSAt),
    selectedRow.preExamEAt === null
      ? new Date()
      : new Date(selectedRow.preExamEAt),
  ]);
  const [examValue, setExamValue] = useState([
    selectedRow.examSAt === null ? new Date() : new Date(selectedRow.examSAt),
    selectedRow.examEAt === null ? new Date() : new Date(selectedRow.examEAt),
  ]);

  //* 고객사 담을 배열
  const [options, setOptions] = useState([]);

  //* 고객사 param
  const [companyListParamValues, setCompanyListParamValues] = useState({
    pageNo: 1,
    perPage: 99,
  });

  //* 규준 리스트 가져오는 param
  const [paramValues, setParamValues] = useState({
    companyIdx: selectedRow.companyIdx,
  });

  //* 고객사 선택 state
  const [defaultSelectItem, setDefaultSelectItem] = useState({
    key: 0,
    label: "",
  });

  //* 고객사 조회 query (ACG ADMIN에서만 쓰일 것)
  const { data, pageInfo, isLoading, isFetching, isError, refetch } =
    useGetQueryhr(
      "getCompanyList",
      companyListParamValues,
      queryToggleCompanyList,
      pilotFlag
    );
  //* 고객사 등록 Mutation
  const { mutate } = useMutationHook("updateGroup", "HR", pilotFlag);

  //* 규준 조회 query
  const { data: normList } = useGetQueryhr(
    "normList",
    paramValues,
    true,
    pilotFlag
  );

  //* ACG ADMIN 판별 (토큰)
  useEffect(() => {
    companyInfo.companyCode === "ACG" && setIsACG(true);
  }, [companyInfo]);

  useEffect(() => {
    setDefaultSelectItem((prev) => ({
      ...prev,
      key: selectedRow.companyIdx,
      label: selectedRow.companyName,
    }));

    setParamValues((prev) => ({
      ...prev,
      companyIdx: selectedRow.companyIdx,
    }));

    const temp = Object.assign({}, defaultValues);
    for (const key in temp) {
      if (key === "testGroupYN") temp[key] = selectedRow[key];
      if (selectedRow[key] !== null) temp[key] = selectedRow[key];
      else delete temp[key];
    }

    setSubmitValues(temp);
  }, [selectedRow]);

  useEffect(() => {
    if (isACG) {
      setSubmitValues((prev) => ({
        ...prev,
        companyIdx: defaultSelectItem.key,
      }));
    }
  }, [defaultSelectItem]);

  useEffect(() => {
    if (submitValues.roomUseYN === "Y") setExpandedRoomUseYN(true);
    else setExpandedRoomUseYN(false);
  }, [submitValues.roomUseYN]);

  useEffect(() => {
    //* ACG가 아니면 규준리스트부터 조회
    if (!isACG) {
      setQueryToggleCompanyList(false);
    } else {
      setQueryToggleCompanyList(true);
    }

    return () => {
      setQueryToggleCompanyList(false);
    };
  }, [isACG]);

  useEffect(() => {
    data &&
      setOptions(
        data.companyList.map((item) => ({
          label: item.companyName,
          key: item.companyIdx,
        }))
      );
  }, [data]);

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    onClose();
    queryClient.invalidateQueries({
      queryKey: [key],
    });
    setSelected([]);
  }, []);

  useEffect(() => {
    focusManager.setFocused(false);
    return () => focusManager.setFocused(undefined);
  }, []);

  const handleCondition = (e) => {
    let { name, value } = e.target;

    setSubmitValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleNumberValue = (e) => {
    let { name, value } = e.target;
    if (name === "roomMaxUserCnt")
      setSubmitValues((prev) => ({ ...prev, [name]: Number(value) }));
  };

  const handleExamMAP = (e) => {
    let { name, value } = e.target;

    setSubmitValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleReport = (e) => {
    let { name, value } = e.target;

    setSubmitValues((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (examValue !== null && preExamValue !== null) {
      setSubmitValues((prev) => ({ ...prev, examSAt: examValue[0] }));
      setSubmitValues((prev) => ({ ...prev, examEAt: examValue[1] }));
      setSubmitValues((prev) => ({ ...prev, preExamSAt: preExamValue[0] }));
      setSubmitValues((prev) => ({ ...prev, preExamEAt: preExamValue[1] }));
    }
  }, [examValue, preExamValue]);

  const dateValidation = (preExamEAt, examSAt) => {
    if (
      preExamEAt instanceof Date &&
      !isNaN(preExamEAt) &&
      examSAt instanceof Date &&
      !isNaN(examSAt)
    ) {
      if (preExamEAt.getTime() >= examSAt.getTime()) return false;
      else return true;
    } else {
      if (new Date(preExamEAt).getTime() >= new Date(examSAt).getTime())
        return false;
      else return true;
    }
  };

  const handleSelectCompany = (_, value) => {
    setDefaultSelectItem((prev) => ({
      ...prev,
      key: value?.key,
      label: value?.label,
    }));

    setParamValues((prev) => ({
      ...prev,
      companyIdx: value.key,
    }));

    setSubmitValues((prev) => ({
      ...prev,
      companyIdx: defaultSelectItem.key,
    }));
  };

  const preExamNullChange = (input) => {
    let temp = { ...input };

    if (temp.examMAP === "0") {
      if (temp.roomUseYN === "N") {
        temp.preExamEAt = null;
        temp.preExamSAt = null;
      }
    } else if (temp.examMAP === "1") {
      temp.preExamEAt = null;
      temp.preExamSAt = null;
    } else if (temp.examMAP === "2") {
      if (temp.roomUseYN === "N") {
        temp.preExamEAt = null;
        temp.preExamSAt = null;
      }
    }
    return temp;
  };

  const handleTestGroupYN = (e) => {
    const { checked } = e.target;

    if (checked) {
      setSubmitValues((prev) => ({ ...prev, testGroupYN: "Y" }));
    } else {
      setSubmitValues((prev) => ({ ...prev, testGroupYN: "N" }));
    }
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [completed, setCompleted] = React.useState({});

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleSubmit = () => {
    if (submitValues.roomUseYN === "Y") {
      if (!dateValidation(submitValues.preExamEAt, submitValues.examSAt))
        return Swal.fire(
          alertModalConfig({
            text: "본 검사 시작일은 사전검사 종료일 이후 날짜로 등록하여야 합니다.",
            title: "공고 수정",
            iconHtml: warningIcon,
            confirmButtonText: "확인",
          })
        );
    }
    if (submitValues?.groupName.includes("/"))
      return Swal.fire(
        alertModalConfig({
          text: `공고명 입력시 '/'를 제외하고 입력해 주세요.`,
          title: "공고 등록",
          iconHtml: warningIcon,
          confirmButtonText: "확인",
        })
      );

    let output = preExamNullChange(submitValues);
    console.log("output: ", output);

    if (selectedRow.companyRoomMaxUserCnt < submitValues.roomMaxUserCnt) {
      return Swal.fire(
        alertModalConfig({
          text: `최대 ${selectedRow.companyRoomMaxUserCnt}명을 초과할 수 없습니다.`,
          title: "공고 수정",
          iconHtml: warningIcon,
          confirmButtonText: "확인",
        })
      );
    } else {
      Swal.fire(
        confirmModalConfig({
          text: MESSAGE_UPDATE_GROUP,
          title: "공고 수정",
          iconHtml: questionIcon,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
        })
      ).then((result) => {
        if (result.isConfirmed) {
          mutate(
            {
              groupIdx: selectedRow.groupIdx,
              submitValues: output,
            },
            {
              onSuccess: () => {
                Swal.fire(
                  alertModalConfig({
                    text: "공고 수정이 완료되었습니다.",
                    title: "공고 수정",
                    iconHtml: checkIcon,
                    confirmButtonText: "확인",
                  })
                ).then((result) => {
                  if (result.isConfirmed) alertResult("totalGroup");
                });
              },
            }
          );
        }
      });
    }
  };

  const handlePreExamValue = (value) => {
    setPreExamValue(value);
  };
  const handleExamValue = (value) => {
    setExamValue(value);
  };

  // const handlePart = (e) => {
  //   const { value } = e.target;
  //   setSubmitValues((prev) => ({ ...prev, partIdx: value }));
  // };

  return (
    <>
      <DialogTitle variant="h6" fontWeight="bold">
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            <Typography variant="h6" fontWeight={700}>
              공고수정
              <Typography
                variant="body1"
                color={"#777"}
                component="span"
                ml={1}
              >
                등록:{selectedRow.userName}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Grid container alignItems="center" rowGap={0.8} direction="column">
              <Grid container columnGap={1} justifyContent="flex-end">
                {selectedRow?.approveYN === "Y" ? (
                  <>
                    <Typography>
                      담당자 : {selectedRow?.adminName || ""}
                    </Typography>
                    <Typography
                      textAlign={"center"}
                      px={0.9}
                      py={0.2}
                      borderRadius={1}
                      bgcolor="#ddebff"
                      fontWeight={700}
                      color="#0052cc"
                      fontSize="0.9rem"
                    >
                      확정
                    </Typography>
                  </>
                ) : (
                  <Typography
                    textAlign={"center"}
                    px={0.9}
                    py={0.2}
                    borderRadius={1}
                    bgcolor="#ffe3eb"
                    fontWeight={700}
                    color="#FF004E"
                    fontSize="0.9rem"
                  >
                    미확정
                  </Typography>
                )}
              </Grid>
              {companyInfo.companyIdx === 1 && (
                <Grid container justifyContent="flex-end">
                  <FormControlLabel
                    labelPlacement="start"
                    sx={{ m: 0, columnGap: 1 }}
                    control={
                      <ConfirmSwitch
                        checked={
                          submitValues.testGroupYN === "Y" ? true : false
                        }
                        onClick={handleTestGroupYN}
                      />
                    }
                    label={"테스트공고"}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ px: 5, pb: 0, height: 500 }}>
        {/* <Grid container rowSpacing={1} mt={0.5} px={3} flexWrap="nowrap">
          <Grid
            item
            xs={6}
            container
            flexDirection={"column"}
            pr={3}
            minHeight={300}
          >
            {isACG && (
              <Grid item xs>
                <Title>고객사선택</Title>
                <Autocomplete
                  options={options}
                  fullWidth
                  size="small"
                  onChange={(_, value) => handleSelectCompany(_, value)}
                  value={{
                    label: defaultSelectItem?.label,
                    key: defaultSelectItem?.key,
                  }}
                  getOptionLabel={(option) => option.label || ""}
                  ListboxProps={{ style: { maxHeight: 200 } }}
                  renderOption={(props, option) => {
                    return (
                      <MenuItem {...props} key={option.key}>
                        {option.label}
                      </MenuItem>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="고객사를 선택해 주세요"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs>
              <Title>본부/부문</Title>
              <Select
                size="small"
                fullWidth
                name="normIdx"
                onChange={handlePart}
                value={submitValues.partIdx}
              >
                {partList?.map((item) => {
                  return (
                    <MenuItem key={item.partIdx} value={item.partIdx}>
                      {item.partName}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs>
              <Title>
                공고명{" "}
                <Typography
                  component="span"
                  variant="body2"
                  color="#5c5c5c"
                  ml={1}
                >
                  ' / '기호를 제외하고 입력해 주세요.
                </Typography>
              </Title>
              <TextField
                fullWidth
                size="small"
                name="groupName"
                value={submitValues.groupName}
                onChange={handleCondition}
              />
            </Grid>
            <Grid item xs>
              <Title>검사 SET</Title>
              <Select
                size="small"
                fullWidth
                name="normIdx"
                onChange={handleReport}
                value={submitValues.normIdx}
              >
                {normList?.map((item) => {
                  return (
                    <MenuItem key={item.normIdx} value={item.normIdx}>
                      {item.normName}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <Divider flexItem orientation="vertical" />
          <Grid item xs={6} container flexDirection={"column"} rowSpacing={3}>
            <Grid item xs pl={3}>
              <Title>검사유형</Title>
              <RadioGroup
                defaultValue={"0"}
                size="small"
                name="examMAP"
                row
                value={submitValues.examMAP}
                onChange={handleExamMAP}
              >
                <FormControlLabel
                  value={"0"}
                  control={
                    <Radio
                      disabled={submitValues.examMAP === "2" ? true : false}
                    />
                  }
                  label="인적성/인성"
                />
                <FormControlLabel
                  value={"1"}
                  control={<Radio size="small" />}
                  label="적성"
                />
                <FormControlLabel
                  value={"2"}
                  control={<Radio size="small" />}
                  label="인성"
                />
              </RadioGroup>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Grid container flexDirection={"column"} pl={2}>
              <Grid
                item
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Title>화상감독</Title>
                <RadioGroup
                  row
                  name="roomUseYN"
                  onChange={handleCondition}
                  value={submitValues.roomUseYN}
                >
                  <FormControlLabel
                    control={<Radio size="small" />}
                    label="온라인"
                    value="Y"
                  />
                  <FormControlLabel
                    control={<Radio size="small" />}
                    label="오프라인"
                    value="N"
                  />
                </RadioGroup>
              </Grid>

              <Collapse in={expandedRoomUseYN} timeout="auto" unmountOnExit>
                <Grid
                  bgcolor="#eee"
                  p={2}
                  px={3}
                  borderRadius={2}
                  container
                  flexDirection="column"
                  justifyContent={"center"}
                  alignItems="center"
                  mb={2}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    flexDirection="column"
                    justifyContent="space-between"
                    rowGap={1}
                  >
                    <Grid item>
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography>화상룸 인원배정</Typography>
                        <TextField
                          size="small"
                          sx={{
                            background: "white",
                            maxWidth: 100,
                            borderRadius: 1,
                            "&>div>input": { textAlign: "center" },
                          }}
                          InputProps={{
                            inputProps: {
                              min: 1,
                              max: selectedRow.companyRoomMaxUserCnt,
                            },
                          }}
                          type={"number"}
                          name="roomMaxUserCnt"
                          defaultValue={submitValues.roomMaxUserCnt}
                          onChange={handleNumberValue}
                          FormHelperTextProps={{
                            sx: {
                              margin: 0,
                              height: 0,
                              textAlign: "right",
                            },
                          }}
                          helperText={
                            <Typography
                              component="span"
                              variant="body2"
                              style={{
                                color: "grey",
                                textAlign: "right",
                              }}
                            >{`* 최대 ${selectedRow.companyRoomMaxUserCnt}명`}</Typography>
                          }
                        />
                      </Stack>
                    </Grid>

                  
                    {(submitValues.examMAP === "0" ||
                      submitValues.examMAP === "2") && (
                      <Grid item xs={12}>
                        <Stack
                          direction={"column"}
                          alignItems="flex-start"
                          justifyContent="center"
                          rowGap={0.5}
                        >
                          <Typography>사전점검 일정</Typography>
                          <DateTimeRangePicker
                            disableClock
                            rangeDivider="~"
                            formatDay={(locale, date) =>
                              dayjs(date).format("D")
                            }
                            locale="ko-KO" // 한글버전
                            calendarType="US"
                            onChange={handlePreExamValue}
                            value={preExamValue}
                            className="preExamCalendar"
                            calendarClassName="openCalendar"
                            calendarIcon={<CalendarMonthRoundedIcon />}
                            clearIcon={null}
                          />
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Stack direction={"column"} pl={3}>
              <Title>본 검사 일정</Title>

              <Grid>
                <DateTimeRangePicker
                  disableClock
                  formatDay={(locale, date) => dayjs(date).format("D")}
                  locale="ko-KO" // 한글버전
                  calendarType="US"
                  onChange={handleExamValue}
                  value={examValue}
                  className="preExamCalendar"
                  calendarClassName="openCalendar"
                  calendarIcon={<CalendarMonthRoundedIcon />}
                  clearIcon={null}
                />
              </Grid>
            </Stack>
          </Grid>
        </Grid> */}

        <Stepper nonLinear activeStep={activeStep} sx={{ px: 3, pt: 2 }}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                disableRipple
                color="inherit"
                onClick={handleStep(index)}
                sx={{
                  "&>.MuiStepLabel-root>.MuiStepLabel-labelContainer>span": {
                    fontSize: 14,
                  },
                  "&>.MuiStepLabel-root>.MuiStepLabel-iconContainer>svg>.MuiStepIcon-text":
                    {
                      fontSize: "1.5rem",
                    },
                }}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep + 1 === 1 ? (
              <Grid
                container
                direction="column"
                rowGap={3}
                mt={5}
                height={"calc(100% - 150px)"}
              >
                {isACG ? (
                  <Box>
                    <Title>회사 선택</Title>
                    <Autocomplete
                      options={options}
                      fullWidth
                      size="small"
                      onChange={(_, value) => {
                        setDefaultSelectItem((prev) => ({
                          ...prev,
                          key: value?.key,
                          label: value?.label,
                        }));
                      }}
                      value={{
                        label: defaultSelectItem?.label,
                        key: defaultSelectItem?.key,
                      }}
                      getOptionLabel={(option) => option.label || ""}
                      ListboxProps={{ style: { maxHeight: 200 } }}
                      renderOption={(props, option) => {
                        return (
                          <MenuItem {...props} key={option.key}>
                            {option.label}
                          </MenuItem>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="회사를 선택해 주세요"
                        />
                      )}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Title>회사명</Title>
                    <Typography ml={2} fontWeight={700} fontSize={"1.1rem"}>
                      {companyInfo?.companyName}
                    </Typography>
                  </Box>
                )}

                <Box>
                  <Title>
                    공고명
                    <Typography
                      component="span"
                      variant="body2"
                      color="#5c5c5c"
                      ml={1}
                    >
                      ' / '기호를 제외하고 입력해 주세요.
                    </Typography>
                  </Title>
                  <TextField
                    fullWidth
                    size="small"
                    name="groupName"
                    value={submitValues.groupName}
                    onChange={handleCondition}
                  />
                </Box>
              </Grid>
            ) : activeStep + 1 === 2 ? (
              <Grid
                container
                direction="column"
                rowGap={3}
                mt={5}
                height={"calc(100% - 150px)"}
              >
                <Box>
                  <Title>검사 유형</Title>
                  <RadioGroup
                    defaultValue={"0"}
                    size="small"
                    name="examMAP"
                    value={submitValues.examMAP || "2"}
                    onChange={handleExamMAP}
                    sx={{ ml: 3, mt: 1 }}
                  >
                    <FormControlLabel
                      value={"0"}
                      control={<Radio disabled={isACG ? false : true} />}
                      label="인적성 검사"
                    />
                    <FormHelperText sx={{ m: 0, ml: 3 }}>
                      - 화상감독 : 사전점검 + 인적성검사
                    </FormHelperText>
                    <FormControlLabel
                      value={"2"}
                      control={<Radio size="small" />}
                      label="인성검사"
                    />
                    <FormHelperText sx={{ m: 0, ml: 3 }}>
                      - 무감독 : AI 감독
                    </FormHelperText>
                    <FormControlLabel
                      value={"1"}
                      control={<Radio size="small" />}
                      label="적성검사"
                    />
                    <FormHelperText sx={{ m: 0, ml: 3 }}>
                      - 화상감독 : 인성(무감독) + 적성(화상감독) <br />- 무감독
                      : 방문응시(미적용)
                    </FormHelperText>
                  </RadioGroup>
                </Box>
                <Box>
                  <Title>검사 SET</Title>
                  <Select
                    size="small"
                    fullWidth
                    name="normIdx"
                    onChange={handleReport}
                    value={submitValues.normIdx}
                  >
                    {normList?.map((item) => {
                      return (
                        <MenuItem key={item.normIdx} value={item.normIdx}>
                          {item.normName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </Grid>
            ) : activeStep + 1 === 3 ? (
              <Grid
                container
                direction="column"
                rowGap={2}
                mt={5}
                height={"calc(100% - 150px)"}
              >
                <Box>
                  <Title>검사 옵션</Title>
                  <Grid
                    item
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                    px={3}
                  >
                    <Typography fontWeight={700}>감독 방식</Typography>
                    <RadioGroup
                      row
                      name="roomUseYN"
                      onChange={handleCondition}
                      value={submitValues.roomUseYN}
                      defaultValue="Y"
                    >
                      <FormControlLabel
                        control={<Radio size="small" />}
                        label="무감독"
                        value="N"
                      />
                      <FormControlLabel
                        control={
                          <Radio size="small" disabled={isACG ? false : true} />
                        }
                        label="화상 감독"
                        value="Y"
                      />
                    </RadioGroup>
                  </Grid>

                  <Collapse
                    in={expandedRoomUseYN}
                    timeout="auto"
                    unmountOnExit
                    sx={{ px: 2 }}
                  >
                    <Grid bgcolor="#eee" p={2} px={3} borderRadius={2} mb={2}>
                      <Grid
                        item
                        container
                        flexDirection="column"
                        justifyContent="space-between"
                        rowGap={2}
                      >
                        <Grid item>
                          <Stack
                            direction="row"
                            alignItems="center"
                            columnGap={3}
                          >
                            <Typography mb={0.5}>화상룸 인원배정</Typography>
                            <TextField
                              size="small"
                              sx={{
                                background: "white",
                                width: 100,
                                borderRadius: 1,
                                "&>div>input": { textAlign: "center" },
                              }}
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  max: 25,
                                },
                              }}
                              type={"number"}
                              name="roomMaxUserCnt"
                              value={submitValues.roomMaxUserCnt}
                              onChange={handleNumberValue}
                            />
                          </Stack>
                        </Grid>
                        {(submitValues.examMAP === "0" ||
                          submitValues.examMAP === "2") && (
                          <Grid item>
                            <Stack
                              direction={"column"}
                              alignItems="flex-start"
                              justifyContent="center"
                            >
                              <Typography mb={0.5}>사전점검 일정</Typography>
                              <DateTimeRangePicker
                                disableClock
                                formatDay={(locale, date) =>
                                  dayjs(date).format("D")
                                }
                                calendarType="US"
                                locale="ko-KO" // 한글버전
                                onChange={handlePreExamValue}
                                value={preExamValue}
                                className="preExamCalendar"
                                calendarClassName="openCalendar"
                                calendarIcon={<CalendarMonthRoundedIcon />}
                                clearIcon={null}
                              />
                            </Stack>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Collapse>
                </Box>
                <Box>
                  <Title>검사 일정</Title>
                  <Grid>
                    <DateTimeRangePicker
                      disableClock
                      formatDay={(locale, date) => dayjs(date).format("D")}
                      locale="ko-KO" // 한글버전
                      calendarType="US"
                      onChange={handleExamValue}
                      value={examValue}
                      className="preExamCalendar"
                      calendarClassName="openCalendar"
                      calendarIcon={<CalendarMonthRoundedIcon />}
                      clearIcon={null}
                    />
                  </Grid>
                </Box>
              </Grid>
            ) : null}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 1,
                py: 2,
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                이전
              </Button>
              {activeStep === steps.length - 1 ? null : (
                <Button onClick={handleNext} variant="outlined">
                  다음
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 2 }}>
        <Button variant={"contained"} onClick={handleSubmit}>
          확인
        </Button>
        <Button variant={"outlined"} onClick={onClose}>
          취소
        </Button>
      </DialogActions>
    </>
  );
}
