import { useEffect, useRef, useState } from "react";

// Style
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Search } from "lucide-react";

// Enum

// Library
import { EXAM_MAP_0, EXAM_MAP_1, EXAM_MAP_2 } from "enums/examMAP";

import { useRecoilState, useSetRecoilState } from "recoil";
import { groupSearchValueAtom, paginationAtom } from "recoil/store";
import { timeFormat } from "utils/formatter/dateFormat";
const defaultFilterValues = {
  groupStatus: "",
  roomUseYN: "",
  screenShareUseYN: "",
  mobileShareUseYN: "",
  preExamUseYN: "",
  virtualGroupYN: "",
  sPreDate: "",
  ePreDate: "",
  sExamDate: "",
  eExamDate: "",
  createdSAt: "",
  createdEAt: "",
  roomMaxUserCnt: "",
  companyName: "",
  approveYN: "",
  examMAP: "",
};
export default function SearchGroupList({ setParamValues }) {
  const [filterCondition, setFilterCondition] = useState(defaultFilterValues);
  const setCurPage = useSetRecoilState(paginationAtom);
  const [searchValue, setSearchValue] = useRecoilState(groupSearchValueAtom);

  const searchRef = useRef(searchValue);

  const handleSubmitValues = (e) => {
    const { name, value } = e.target;
    setFilterCondition((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  const submitSearch = () => {
    let searchContent = {
      groupFind: searchRef.current.value,
    };
    if (searchRef.current.value === "") setCurPage(1);
    setSearchValue(searchRef.current.value);

    let submit = { ...searchContent, ...filterCondition };

    // 필터만 적용했을 경우
    for (const key in submit) {
      if (submit[key] === "TOTAL") delete submit[key];
    }

    // 필터만 적용했을 경우
    for (const key in submit) {
      if (key === "") delete submit[""];
    }

    // 필터 미적용 항목 삭제
    for (const key in submit) {
      if (submit[key] === "") delete submit[key];
    }

    for (const key in submit) {
      if (
        key === "sPreDate" ||
        key === "ePreDate" ||
        key === "sExamDate" ||
        key === "eExamDate" ||
        key === "createdSAt" ||
        key === "createdEAt"
      )
        submit[key] = timeFormat(submit[key]);
    }

    setParamValues({ pageNo: 1, ...submit });
  };
  return (
    <Grid container columnGap={1}>
      <TextField
        select
        label="감독방식"
        size="small"
        name="roomUseYN"
        onChange={handleSubmitValues}
        value={filterCondition?.roomUseYN}
        sx={{ minWidth: 100 }}
      >
        <MenuItem key={"total"} value={"TOTAL"}>
          전체
        </MenuItem>
        <MenuItem key={"Y"} value={"Y"}>
          화상감독
        </MenuItem>
        <MenuItem key={"N"} value={"N"}>
          무감독
        </MenuItem>
      </TextField>
      <TextField
        select
        label="검사유형"
        size="small"
        name="examMAP"
        onChange={handleSubmitValues}
        value={filterCondition?.examMAP}
        sx={{ minWidth: 100 }}
      >
        <MenuItem key={"total"} value={"TOTAL"}>
          전체
        </MenuItem>
        <MenuItem key={"0"} value={"0"}>
          {EXAM_MAP_0}
        </MenuItem>
        <MenuItem key={"2"} value={"2"}>
          {EXAM_MAP_2}
        </MenuItem>
        <MenuItem key={"1"} value={"1"}>
          {EXAM_MAP_1}
        </MenuItem>
      </TextField>

      <Grid item>
        <TextField
          size="small"
          inputRef={searchRef}
          defaultValue={searchValue}
          sx={{ minWidth: 300 }}
          placeholder="공고명 또는 검사시작 날짜를 입력해주세요"
          onKeyDown={(e) => {
            if (e.key === "Enter") submitSearch();
          }}
        />
      </Grid>
      <Grid item>
        <Button
          startIcon={<Search size={15} />}
          sx={{ height: "100%" }}
          variant={"outlined"}
          onClick={submitSearch}
        >
          검색
        </Button>
      </Grid>
    </Grid>
  );
}
