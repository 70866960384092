import React, { useState } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useNavigate, useParams } from "react-router-dom";

import { YNColor } from "utils/formatter/YNColor";
import { examStatusTag } from "utils/tagRender";

const RoomTesterListCard = ({ props = {} }) => {
  console.log("props: ", props);
  //   console.log("props: ", props);
  const {
    testerBirth,
    testerCell,
    examStatus,
    attendanceYN,
    preExamYN,
    testerName,
    foreignLoginYN,
    testerIdx,
  } = props;

  const [open, setOpen] = useState(false);

  const { id } = useParams(); //*RoomIdx
  const navigate = useNavigate();
  return (
    <Grid item container flexDirection="column">
      <Card
        sx={{ padding: 1.5, border: "1px solid #DFDFFF" }}
        variant="outlined"
      >
        <Grid container flexDirection="column">
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Checkbox
              size="small"
              color="primary"
              sx={{ padding: 0 }}
              onClick={(event) => props.handleCheckClick(event, props)}
              checked={props.isSelected(testerIdx)}
            />
            <Box>
              <Grid container>
                <Typography fontWeight={700}>{testerName}</Typography>
                <Divider flexItem orientation="vertical" sx={{ m: "0 5px" }} />
                <Typography>{testerCell}</Typography>
              </Grid>
            </Box>
            <Box>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Box>
          </Grid>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container rowGap={0.7}>
              <Grid item container columnGap={1}>
                <Typography fontWeight={700} width={50}>
                  생년월일
                </Typography>
                <Typography>{testerBirth}</Typography>
              </Grid>
              <Grid item container columnGap={1} alignItems="center">
                <Typography fontWeight={700} width={50}>
                  응시현황
                </Typography>
                <Typography>{examStatusTag(examStatus)}</Typography>
              </Grid>
              <Grid
                item
                container
                columnGap={2}
                border={"1.5px solid #eac6ff97"}
                bgcolor={"#fcf8ff97"}
                borderRadius={1}
                alignItems="center"
                justifyContent={"space-evenly"}
                px={1}
              >
                <Grid item flexDirection={"column"} xs>
                  <Typography textAlign={"center"} fontWeight={700}>
                    사전점검
                  </Typography>
                  <Typography textAlign={"center"}>
                    {YNColor(preExamYN)}
                  </Typography>
                </Grid>
                <Divider flexItem orientation="vertical" />
                <Grid item flexDirection={"column"} xs>
                  <Typography textAlign={"center"} fontWeight={700}>
                    해외응시
                  </Typography>
                  <Typography textAlign={"center"}>
                    {YNColor(foreignLoginYN)}
                  </Typography>
                </Grid>
                <Divider flexItem orientation="vertical" />
                <Grid item flexDirection={"column"} xs>
                  <Typography textAlign={"center"} fontWeight={700}>
                    출석여부
                  </Typography>
                  <Typography textAlign={"center"}>
                    {YNColor(attendanceYN)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Card>
    </Grid>
  );
};

export default RoomTesterListCard;
