import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useRef } from "react";
import useMutationHook from "hooks/useMutationHook";
import { MESSAGE_SIGNUP_HR } from "enums/modalMessage";
import { Controller, useForm } from "react-hook-form";
import {
  MAX_LENGTH,
  MIN_LENGTH,
  REQUIRE_VALUE,
  VALID_VALUE,
} from "enums/errorMessage";
import { autoHyphen, autoHyphen_UserTel } from "utils/autoHyphen";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import useGetQueryhr from "hooks/useGetQueryhr";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import { HelpCircle } from "lucide-react";

const SignUpHR = () => {
  const { companyCode, userIdx } = useLocation().state || {};
  const [userBaseInfo, setUserBaseInfo] = useState({});
  const [isSuccessRequest, setIsSuccessRequest] = useState(false);
  const [isSuccessVerify, setIsSuccessVerify] = useState(false);

  const [companyLogoTrigger, setCompanyLogoTrigger] = useState(false);
  const [companyLogo, setCompanyLogo] = useState("");
  const {
    data: companyLayout,
    isLoading: companyLayout_isLoading,
    isFetching: companyLayout_isFetching,
    isError: companyLayout_isError,
  } = useGetQueryhr(
    "userLayout",
    {
      companyCode: companyCode,
    },
    companyLogoTrigger
  );

  useEffect(() => {
    companyLayout && setCompanyLogo(companyLayout.layout.bgColor);
  }, [companyLayout]);

  useEffect(() => {
    companyCode !== undefined && setCompanyLogoTrigger(true);
  }, [companyCode]);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userEmail: userBaseInfo.userEmail,
      userPass: "",
      userName: "",
      userCell: "",
      userTel: "",
      userPassConfirm: "",
    },
  });

  const { data } = useGetQueryhr("getUserInfo_signUp", {
    companyCode: companyCode,
    userIdx: userIdx,
  });

  useEffect(() => {
    data && setUserBaseInfo(data);
  }, [data]);

  const navigate = useNavigate();
  const { mutate } = useMutationHook("signUpHR", "HR");
  const { mutate: sendAuthKey } = useMutationHook("sendAuthKey_signUp", "HR");
  const { mutate: verifyAuthKey } = useMutationHook(
    "verifyAuthKey_signUp",
    "HR"
  );
  const isMobile = useMediaQuery("(max-width:770px)");

  const authKeyRef = useRef();

  const userCell = watch("userCell");

  const handleSendAuthKey = () => {
    sendAuthKey(
      { userCell: userCell },
      {
        onSuccess: () => {
          setIsSuccessRequest(true);
        },
      }
    );
  };
  const handleVerifyAuthKey = () => {
    verifyAuthKey(
      { userCell: userCell, authKey: authKeyRef.current.value },
      {
        onSuccess: () => {
          setIsSuccessVerify(true);
        },
      }
    );
  };
  const onSubmit = (data) => {
    delete data.userPassConfirm;

    if (!isSuccessVerify)
      return Swal.fire(
        alertModalConfig({
          text: "휴대전화 번호 인증이 완료되어야 가입이 가능합니다.",
          title: "휴대전화 번호 인증",
          iconHtml: errorIcon,
          confirmButtonText: "확인",
        })
      );
    else
      Swal.fire(
        confirmModalConfig({
          text: MESSAGE_SIGNUP_HR,
          title: "회원가입",
          iconHtml: questionIcon,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
        })
      ).then((result) => {
        if (result.isConfirmed) {
          mutate(
            {
              data,
              userIdx: userIdx,
              companyCode: companyCode,
            },
            {
              onSuccess: () => {
                Swal.fire(
                  alertModalConfig({
                    text: "회원가입이 완료되었습니다.",
                    title: "회원가입",
                    iconHtml: checkIcon,
                    confirmButtonText: "확인",
                  })
                ).then((result) => {
                  if (result.isConfirmed) navigate("/login_hr");
                });
              },
            }
          );
        }
      });
  };

  return (
    <Grid container height={"100vh"} justifyContent={"center"} p={5}>
      <Grid
        boxShadow={"20px 20px 90px #d9d9d9, -20px -20px 60px #ffffff"}
        borderRadius={2}
        container
        position="relative"
      >
        {/* left */}
        <Grid item container justifyContent={"center"} alignItems="center">
          <form>
            <Grid
              container
              flexDirection={"column"}
              rowGap={2}
              justifyContent={"center"}
              minWidth={isMobile ? null : 400}
            >
              <Box position={"absolute"} left={30} top={30}>
                <Box
                  component={"img"}
                  src={userBaseInfo.ciFilePath}
                  width={70}
                />
              </Box>

              <Typography variant="h5" fontWeight={700} textAlign="center">
                회원가입
                <Typography textAlign="center">
                  {/* {companyName || ""} 담당자 */}
                </Typography>
              </Typography>

              <Grid container rowGap={0.5} flexDirection="column">
                <Grid
                  item
                  container
                  flexDirection={"column"}
                  justifyContent={"center"}
                >
                  <Typography variant="body2">권한</Typography>
                  <Select
                    size="small"
                    disabled
                    value={userBaseInfo.UserGradeIdx || 1}
                  >
                    <MenuItem key={"1"} value={1}>
                      메인담당자
                    </MenuItem>
                    <MenuItem key={"2"} value={2}>
                      일반담당자
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid
                item
                container
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Typography
                  variant="body2"
                  sx={{
                    "&:after": {
                      content: '"*"',
                      color: "red",
                    },
                  }}
                >
                  이메일
                </Typography>
                <Grid
                  container
                  alignItems={"stretch"}
                  justifyContent="space-between"
                  columnGap={1}
                >
                  <Grid item xs>
                    <TextField
                      fullWidth
                      disabled
                      value={userBaseInfo.userEmail || ""}
                      size="small"
                      placeholder="이메일을 입력해주세요."
                      error={errors.userEmail && true}
                      helperText={errors && errors.userEmail?.message}
                      FormHelperTextProps={{
                        sx: { margin: 0, height: 0 },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Typography
                  variant="body2"
                  sx={{
                    "&:after": {
                      content: '"*"',
                      color: "red",
                    },
                  }}
                >
                  비밀번호
                </Typography>
                <Controller
                  name="userPass"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: REQUIRE_VALUE,
                    },
                    minLength: {
                      value: 8,
                      message: MIN_LENGTH,
                    },
                    maxLength: {
                      value: 15,
                      message: MAX_LENGTH,
                    },
                    pattern: {
                      value: VALID_VALUE.PW.REG_EXP,
                      message: VALID_VALUE.PW.MESSAGE,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      type={"password"}
                      error={errors.userPass && true}
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            title="8~15자의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요."
                            placement="top"
                            arrow
                          >
                            <InputAdornment position="start">
                              <HelpCircle size={16} strokeWidth={1} />
                            </InputAdornment>
                          </Tooltip>
                        ),
                      }}
                      helperText={
                        errors.userPass?.type === "required"
                          ? errors.userPass.message
                          : errors.userPass?.type === "maxLength"
                          ? errors.userPass.message
                          : errors.userPass?.type === "minLength"
                          ? errors.userPass.message
                          : errors.userPass?.type === "pattern"
                      }
                      FormHelperTextProps={{
                        sx: { margin: 0, textAlign: "right" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                container
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Typography
                  variant="body2"
                  sx={{
                    "&:after": {
                      content: '"*"',
                      color: "red",
                    },
                  }}
                >
                  비밀번호 확인
                </Typography>
                <Controller
                  name="userPassConfirm"
                  control={control}
                  rules={{
                    validate: (val) => {
                      if (watch("userPass") !== val) {
                        return "비밀번호가 일치하지 않습니다.";
                      }
                    },
                    required: {
                      value: true,
                      message: REQUIRE_VALUE,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      type={"password"}
                      error={errors.userPassConfirm && true}
                      helperText={
                        errors.userPassConfirm && errors.userPassConfirm.message
                      }
                      FormHelperTextProps={{
                        sx: { margin: 0 },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                container
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Typography
                  variant="body2"
                  sx={{
                    "&:after": {
                      content: '"*"',
                      color: "red",
                    },
                  }}
                >
                  담당자 성명
                </Typography>
                <Controller
                  name="userName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: REQUIRE_VALUE,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors.userName && true}
                      helperText={errors.userName && errors.userName.message}
                      size="small"
                      FormHelperTextProps={{
                        sx: { margin: 0 },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid container rowGap={0.5} flexDirection="column">
                <Grid
                  item
                  container
                  flexDirection={"column"}
                  justifyContent={"center"}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      "&:after": {
                        content: '"*"',
                        color: "red",
                      },
                    }}
                  >
                    휴대전화 번호
                  </Typography>
                  <Grid
                    container
                    alignItems={"stretch"}
                    justifyContent="space-between"
                    columnGap={1}
                  >
                    <Grid item xs>
                      <Controller
                        name="userCell"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: REQUIRE_VALUE,
                          },
                        }}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            onChange={(e) =>
                              onChange(autoHyphen(e.target.value))
                            }
                            value={value}
                            error={errors.userCell && true}
                            helperText={
                              errors.userCell && errors.userCell.message
                            }
                            fullWidth
                            size="small"
                            FormHelperTextProps={{
                              sx: { margin: 0, height: 0 },
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item>
                      <Button
                        variant="outlined"
                        sx={{ height: "100%" }}
                        onClick={handleSendAuthKey}
                      >
                        인증번호 전송
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {/* //*인증 */}

                {isSuccessRequest && (
                  <Grid
                    item
                    container
                    flexDirection={"column"}
                    justifyContent={"center"}
                  >
                    <Grid
                      container
                      alignItems={"stretch"}
                      justifyContent="space-between"
                      columnGap={1}
                    >
                      <Grid item xs>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="인증번호 6자리 입력"
                          inputRef={authKeyRef}
                          disabled={isSuccessVerify ? true : false}
                        />
                      </Grid>

                      <Grid item flexGrow={0.08}>
                        <Button
                          variant="contained"
                          sx={{
                            height: "100%",
                            "& >.MuiButton-endIcon": { ml: 0.3 },
                          }}
                          fullWidth
                          disabled={isSuccessVerify ? true : false}
                          onClick={handleVerifyAuthKey}
                          endIcon={
                            isSuccessVerify ? (
                              <CheckCircleOutlineRoundedIcon fontSize="small" />
                            ) : null
                          }
                        >
                          {isSuccessVerify ? "완료" : "인증하기"}
                        </Button>
                      </Grid>
                    </Grid>
                    <Typography
                      variant="body2"
                      textAlign={"right"}
                      color={"#777777"}
                    >
                      *휴대전화로 전송된 인증번호 6자리를 입력해주세요.
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                container
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Typography variant="body2">직통번호</Typography>

                <Controller
                  name="userTel"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={(e) =>
                        onChange(autoHyphen_UserTel(e.target.value))
                      }
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item container>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                >
                  회원가입
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignUpHR;
