import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { subMonths } from "date-fns";
import { ko } from "date-fns/locale";
import useMutationHook from "hooks/useMutationHook";
import React, { useRef, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { dateFormat, timeFormat } from "utils/formatter/dateFormat";

export const DownloadCandidateList = () => {
  const [eventReasonModal, setEventReasonModal] = useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const eventReasonRef = useRef();
  const { mutate } = useMutationHook("downloadCandidateList", "HR");

  const [state, setState] = useState([
    {
      startDate: subMonths(new Date(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const downloadList = () => {
    const { startDate, endDate } = state[0];
    const start = timeFormat(startDate);
    const end = timeFormat(endDate);
    mutate(
      { sDate: start, eDate: end, eventReason: eventReasonRef.current.value },
      {
        onSuccess: (res) => {
          window.open(`https://${res.data.data}`);
          closeModal();
        },
      }
    );
  };
  const handleConfirm = () => setEventReasonModal(true);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const closeModal = () => {
    setEventReasonModal(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button onClick={handleConfirm}>응시자 다운로드 </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 7,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Grid container direction="column" gap={2}>
                  <DateRange
                    showPreview={false}
                    editableDateInputs={false}
                    onChange={(item) => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    locale={ko}
                    rangeColors={["#1E283D"]}
                    showDateDisplay={false}
                  />
                  <Grid
                    padding={2}
                    gap={2}
                    width={"100%"}
                    container
                    justifyContent={"space-between"}
                    flexWrap={"nowrap"}
                  >
                    <Button
                      sx={{ width: "50%" }}
                      variant="contained"
                      onClick={handleConfirm}
                    >
                      다운로드
                    </Button>
                    <Button
                      sx={{ width: "50%" }}
                      variant="outlined"
                      onClick={handleClose}
                    >
                      닫기
                    </Button>
                  </Grid>
                </Grid>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Dialog
        open={eventReasonModal}
        onClose={closeModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle variant="h5">응시자 다운로드</DialogTitle>
        <DialogContent>
          <Grid container flexDirection="column" gap={4}>
            <Grid container mt={2} flexDirection="column" gap={1}>
              <Typography fontWeight="bold">선택한 공고 기간</Typography>
              <Typography fontWeight="bold" color={"#777"}>
                {dateFormat(state[0]?.startDate)} ~
                {dateFormat(state[0]?.endDate)}
              </Typography>
            </Grid>

            <Grid container direction="column" gap={1}>
              <Grid item xs={3}>
                <Typography fontWeight="bold">다운로드 사유 입력</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  placeholder="다운로드 사유를 입력해주세요."
                  fullWidth
                  size="small"
                  inputRef={eventReasonRef}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: 3, paddingTop: 1 }}>
          <Button variant={"outlined"} onClick={closeModal}>
            취소
          </Button>
          <Button variant={"contained"} onClick={downloadList}>
            확인
          </Button>{" "}
        </DialogActions>
      </Dialog>
    </>
  );
};
